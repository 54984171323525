import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthService } from "../_service/authentication.service";
import { environment } from "../../../../environments/environment";

@Injectable()
export class IdentityGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate() {
    const ua = navigator.userAgent.toLowerCase();
    var safariVendor = navigator.vendor
      ? navigator.vendor.toLowerCase().match(/apple/i)
      : "";
    const isChrome =
      /chrome\//.test(ua) && !/opera|opr\//.test(ua) && !safariVendor;
    if (environment.withSSO) {
      if (isChrome) {
        return this.authService.checkLogin();
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
