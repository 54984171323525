import { Injectable } from "@angular/core";

interface CacheContent {
  value: any;
}

@Injectable({
  providedIn: "root",
})
export class CacheService {
  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();

  constructor() {}

  public get(key: string): Array<any> {
    if (this.hasValidCachedValue(key)) {
      console.log(`%cGetting from cache ${key} -- ${this.cache}`, "color: green");
      return this.cache.get(key).value;
    }
  }

  public set(key: string, value: any): void {
    
    this.cache.set(key, { value: value });
  }

  public getAllValues(key: string): Array<any> {
    
    const arr = [];
    this.cache.forEach((val, k) => {
      if ((k.includes(key) && k) || key === k) {
        arr.push(...this.cache.get(k).value);
      }
    });
    return arr;
  }

  public removeAllCache(): void {
   
    this.cache.clear();
  }

  public removeCacheKeys(key: string): void {
   
    this.cache.forEach((val, k) => {
      if ((k.includes(key) && k) || key === k) {
        this.cache.delete(k);
      }
    });
  }

  public resetCacheProperty(
    key: string,
    prop: string,
    newvalue: string | boolean | number
  ): void {
    this.cache.forEach((val, k) => {
      if ((k.includes(key) && k) || key === k) {
        val.value.forEach((ele) => {
          ele[prop] = newvalue;
        });
      }
    });
  }

  public hasValidCachedValue(key: string): boolean {
    
    if (this.cache.has(key)) {
      return true;
    } else {
      return false;
    }
  }
  
}
