export const environment = {
  production: false,
  envName: "Dev",
  version: "1.0",
  withSSO: true,
  logging: true,
  popupTimer: 300, //5 minute before
  splunk: {
    URL: "https://agero-dev.apigee.net/ageroappsapi/services/collector/event",
    ApiKey: "29C6eDWRuQdSoSjeybfyTGIFNzTjCpCk",
    splunkEnvAuthKey: "603E0E45-C9D6-58FC-B896-0489891D7C66",
  },
  clientCode: {
    Agero: "AGR",
  },
  API: {
    getSettingsApi:
      "https://agero-dev.apigee.net/ageroappsapi/v1/GetConfigurationSettings",
    apikey: "29C6eDWRuQdSoSjeybfyTGIFNzTjCpCk",
    DPLExistingCasesApi:
      "https://agero-dev.apigee.net/ageroappsapi/v1/GetCallbackSearchCaseData",
    getProgramList:
      "https://agero-dev.apigee.net/ageroappsapi/v1/GetProgramList",
  },
  ageroAuthConfig: {
    clientId: "5k956q566rs71llvhr0obijq3s",
    useMultiTenantApi: true,
    appWebDomain: "login.nonprod.oauth.agero.com",
    redirectUriSignIn: "callback.html",
    redirectUriSignOut: "logout.html",
    apiFullUrl: "https://nonprod.api.oauth.agero.com/multi-nonprod/api/v1",
    refreshTimeoutOffset: 10 * 60,
    // How many seconds of inactivity before the user is signed out.  If not included, defaults to 30 minutes
    activityTimeout: 30 * 60,
  },
  // We need to update the below two URLs on Actual Deployment
  Star2URL: "https://d2rbwj24cp4jjk.cloudfront.net",
  DispatchLiteURL: "https://d2efrr9bp4rc4x.cloudfront.net",

  RSADashboardURL: "https://d2izlsqy5psser.cloudfront.net",
  LocateMeURL: "https://d1q98630xeossm.cloudfront.net/locate.html",
  CTI: "https://d2s8h1a9i5sktp.cloudfront.net/",
  ConfigurationsURL: "https://dgeq1u4s5g2w9.cloudfront.net",
  IVRConfig: "https://d1km5qn8399tmm.cloudfront.net",
  SocketConnectionURL: "wss://hp3p0tiyuj.execute-api.us-east-1.amazonaws.com/dev?header=",
};
