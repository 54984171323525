import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { debug } from "util";

@Injectable()
export class SplunkService {
  corelationID: any = new Date().valueOf() + "" + Math.floor(100000 + Math.random() * 900000);
  browserInformation = window.navigator.userAgent;
  constructor() { }

  public splunkLog(logData): any {
    let splunkPayLoad = {
      event: {
        environment: environment.envName,
        application: "AgeroApps",
        Body: {
          logLevel: logData.logLevel,
          logDescription: logData.logDescription,
          logMessage: logData.logMessage,
          logStory : logData.logStory,
          correlationid: this.corelationID,
          deviceInfo: {
            platform: navigator.platform,
            userAgent: navigator.userAgent
          }
        }
      }
    };
    let Authorization = environment.splunk.ApiKey;
    let SplunkAuthorization = environment.splunk.splunkEnvAuthKey;
    let xhr = new XMLHttpRequest();
    xhr.open("POST", environment.splunk.URL);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", Authorization);
    xhr.setRequestHeader("SplunkAuthorization", SplunkAuthorization);
    xhr.send(JSON.stringify(splunkPayLoad));
  }

}
