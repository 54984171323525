import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { SplunkService } from "./splunk.service";

@Injectable()
export class WebsocketService {
  socket: any;
  socketConnectionUrl: any;
  socketHeader: any;
  connectionData: any;

  constructor(private splunkService: SplunkService) { }

  public socketBS = new BehaviorSubject(null);
  socketConn = this.socketBS.asObservable();

  public connect(url) {

    this.startWebSocket(url);
  }

  startWebSocket(url) {

    this.socket = new WebSocket(url);
    localStorage.setItem("socketConnected", "true");
    localStorage.setItem("socketConnectedExtn", localStorage.getItem('agentExtentionNumber'));
    var that = this;

    this.socket.onopen = function (event) {
      console.log("Websocket service connected to  " + url); 

      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        logDescription: "Socket connection opened",
        logMessage: "WebSocket connection established for agent extension " + localStorage.getItem("socketConnectedExtn") +  " and the socket is connected to " + url,
        logStory: "WebSocket connection opened for agent having valid extension."
      };
      that.splunkService.splunkLog(logData); 

      setInterval(function () {
        that.socket.send("Client To Server" + new Date());
      }, 540000)
    }

    this.socket.onmessage = (event) => {
      console.log("Message received");
      let response = JSON.parse(event.data);

      that.socketBS.next(response);

      let logData = {
        logLevel: "Info",
        logDescription: "Socket on message triggered.",
        logMessage: response,
        logStory: "Agent received the call.",
      };
      that.splunkService.splunkLog(logData); 
      console.log(JSON.stringify(logData));
    }

    this.socket.onclose = function (event) {
      console.log("Socket connection closed..")
      this.socket = null;
      let logData = {
        logLevel: "Info",
        logDescription: "Socket on close.",
        logMessage: "NA",
        logStory: "Websocket on close triggered.",
      };
      that.splunkService.splunkLog(logData); 

      //Handling socket close event on extention change
      if (localStorage.getItem("newExtensionEntered") != "true")
      {
        setTimeout(function () { that.startWebSocket(url) }, 5000)

        let logData = {
          logLevel: "Info",
          logDescription: "Reestablish Websocket connection on timeout",
          logMessage: "Reestablish Websocket connection to " + url,
          logStory: "Websocket connection is reestablished.",
        };
        that.splunkService.splunkLog(logData); 
        console.log("New socket connection established..");
      }
      else
      {
        localStorage.setItem("newExtensionEntered", "false");
      } 
    }
  }

  closeSocket() {

    this.socket.close();
    console.log("Close socket connection..")

    let logData = {
      logLevel: "Info",
      logDescription: "Close socket connection",
      logMessage: "NA",
      logStory: "Websocket connection is closed.",
    };
    this.splunkService.splunkLog(logData); 
  }
}
