import {
  MatDialogModule,
  MatProgressBarModule,
  MatCardModule,
  MatIconModule,
  MatButtonModule,
} from "@angular/material";
import { MatSelectModule } from "@angular/material/select";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule
  ],
})
export class MaterialModule {}
