import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CasesearchService {

  constructor(
    private http: HttpClient
  ) { }

  public getExistingJobs(callbackPayload: any): Observable<any> {    
    let url = `${environment.API.DPLExistingCasesApi}`;
    return this.http.post(url, callbackPayload);
  }

  public getProgramList(): Observable<any> {
    let programUrl = `${environment.API.getProgramList}`;
    return this.http.get(programUrl);      
  }
}
