import { ModuleWithProviders, NgModule } from "@angular/core";
import { MaterialModule } from "./material.module";
import { SafeUrl } from "../../shared/_pipes/safeurl.pipe";
import { MaintenanceComponent } from "../_components/maintenance/maintenance.component";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "../_components/header/header.component";

@NgModule({
  imports: [MaterialModule, CommonModule],
  declarations: [SafeUrl, MaintenanceComponent, HeaderComponent],
  exports: [
    MaterialModule,
    CommonModule,
    MaintenanceComponent,
    HeaderComponent,
    SafeUrl,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
