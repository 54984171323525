import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

const logger = (function () {
  let oldConsoleLog = null;
  return {
    enableLogger: function enableLogger() {
      if (oldConsoleLog === null) {
        return;
      }

      window["console"]["log"] = oldConsoleLog;
    },
    disableLogger: function disableLogger() {
      oldConsoleLog = console.log;
      window["console"]["log"] = function () {};
    },
  };
})();
if (environment.production) {
  logger.disableLogger();
} else {
  logger.enableLogger();
}

if (environment.production) {
  enableProdMode();
}

if (environment.envName == "Prod" || environment.envName == "Prodwest") {
  const script = document.createElement('script');
  script.type = "text/javascript";
  script.src = "https://d1ilwtwcvll3qa.cloudfront.net/cti/elements/cti-elements-es2015.js"
  document.body.appendChild(script);
} 
else if(environment.envName == "Dev" || environment.envName == "DevWEST"){
  console.log("Dev Environment");
  const script = document.createElement('script');
  script.type = "text/javascript";
  script.src = "https://d2y0nnnghfk48m.cloudfront.net/cti/elements/cti-elements-es2015.js"
  document.body.appendChild(script);
}
else if(environment.envName == "QA" || environment.envName == "QAWest"){
  const script = document.createElement('script');
  script.type = "text/javascript";    
  script.src = "https://dla7b0vvzj1q2.cloudfront.net/cti/elements/cti-elements-es2015.js"
  document.body.appendChild(script);
}
else if(environment.envName == "Stage" || environment.envName == "StageWest"){
  const script = document.createElement('script');
  script.type = "text/javascript";
  script.src = "https://d24tr2qxx3ufgn.cloudfront.net/cti/elements/cti-elements-es2015.js"
  document.body.appendChild(script);
}
else if(environment.envName == "Train" || environment.envName == "TrainWest"){
  const script = document.createElement('script');
  script.type = "text/javascript";    
  script.src = "https://d36wzcvvpaixpj.cloudfront.net/cti/elements/cti-elements-es2015.js"
  document.body.appendChild(script);
}
else {
  const script = document.createElement('script');
  script.type = "text/javascript";
  script.src = "https://d2y0nnnghfk48m.cloudfront.net/cti/elements/cti-elements-es2015.js"
  document.body.appendChild(script);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
