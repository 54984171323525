import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";

import { tap } from "rxjs/operators";
import { SplunkService } from "../../_modules/dashboard/_service/splunk.service";
import { AuthService } from "../../_modules/dashboard/_service/authentication.service";
// Environment
import { environment } from "../../../environments/environment";

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(
    private splunkService: SplunkService,
    private authService: AuthService,
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Log only if its set in the environment

    if (environment.logging) {

      if (!request.headers.has("noLog")) {
        // splunk log calls should n't go for log
        let correlationid = this.splunkService.corelationID; //Unique id for the session
        let eventAction = "";
        console.log("in the logger");
        // getting the user action that triggered the API request
        if (request.headers.has("userTriggerEvent")) {
          eventAction = request.headers.get("userTriggerEvent");
          request = request.clone({
            headers: request.headers.delete("userTriggerEvent")
          });
        }
        eventAction =
          eventAction +
          " triggered the api request - url : " +
          request.url +
          ", Body: " +
          JSON.stringify(request.body);
        let logData = {
          logLevel: "Info",
          logDescription: "API request",
          logMessage: eventAction,
          logStory : "Triggered an api request to url : " +  request.url
        };

        this.splunkService.splunkLog(logData);
        /*send click event to google analytics*/

        return next.handle(request).pipe(
          tap(
            (event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                logData = {
                  logLevel: "Info",
                  logDescription: "Successful API request",
                  logMessage: JSON.stringify(event),
                  logStory : "Successful api request - url : " +  request.url
                };
                this.splunkService.splunkLog(logData);
              }
            },
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                let programCode = localStorage.getItem("currentProgramCode");
                let decodedUserCode = this.authService.getDecodedIdToken().programCode;
                if (err.status === 401 || err.status === 403) {
                  logData = {
                    logLevel: "Critical",
                    logDescription: "Failed API request unauthorized",
                    logMessage: `Failed API request unauthorized for ${decodedUserCode}:` + JSON.stringify(err),
                    logStory : "Failed API request unauthorized - url : " +  request.url
                  };
                } else {
                  logData = {
                    logLevel: "Error",
                    logDescription: "Failed API request",
                    logMessage: `Failed API request for client ${programCode}:` + JSON.stringify(err),
                    logStory : "Failed API request - url : " +  request.url
                  };
                }
                this.splunkService.splunkLog(logData);
              }
            }
          )
        );
      }
    }
  }
}
