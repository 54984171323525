import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LogoutComponent } from "./_modules/logout/logout.component";
import { CallBackComponent } from "./_modules/callback/callback.component";
import { MaintenanceComponent } from "./shared/_components/maintenance/maintenance.component";

const routes: Routes = [
  { path: "", redirectTo: "/dashboard/home", pathMatch: "full" },
  { path: "callback.html", component: CallBackComponent },
  {
    path: "dashboard",
    loadChildren: "./_modules/dashboard/dashboard.module#DashboardModule",
    data: { meta: { title: "Dashboard" } },
  },
  {
    path: "not-found",
    loadChildren: "./_modules/not-found/not-found.module#NotFoundModule",
  },
  {
    path: "maintenance",
    component: MaintenanceComponent,
  },

  {
    path: "invalid-url",
    loadChildren: "./_modules/invalid-url/invalid-url.module#InvalidUrlModule",
  },
  { path: "logout.html", component: LogoutComponent },

  { path: "**", redirectTo: "invalid-url" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
