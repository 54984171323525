import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-cti-screenpop-dialog",
  templateUrl: "./cti-screenpop-dialog.component.html",
  styleUrls: ["./cti-screenpop-dialog.component.css"],
})
export class CtiScreenpopDialogComponent implements OnInit {
  callbackPhone: string;
  programName: string;
  caller: string;
  jobText: string;
  jobTypeBtnText: string;
  caseData: any;
  programCodes: any = [];
  program: string;
  isExistingJob: boolean = false;
  digitalType: string = "";

  constructor(
    public dialogRef: MatDialogRef<CtiScreenpopDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {    
    if (data) {  
      this.callbackPhone = data.callingNumber;
      this.programName = data.programName;
      this.caller = data.caller;
      this.jobText = data.jobText;
      this.jobTypeBtnText = data.jobTypeBtnText;
      this.programCodes = data.programCodes;
      this.isExistingJob = false;
      if (data.res) {
        this.isExistingJob = true;
        let programListObj = this.programCodes
        .filter(
          (item) => item.code === data.res[0].program
        )
        .map((item) => item.name)[0];
        this.program = programListObj;
        this.caseData = data.res[0];
      }
    }
  }

  ngOnInit() {}

  createNewJob(isNewJobBtnClick: any) {
    this.caseData = isNewJobBtnClick == true ? null : this.caseData;
    if (this.caseData)
    {
      if (this.caseData.rsaRequestID && this.caseData.status == "RequestConfirmed")
      {
        this.digitalType = "IVR";
      }
    }
    let selectedObj = {
      callbackNumber: this.callbackPhone,
      program: this.programName,
      caseId: this.caseData ? this.caseData.caseId : null,
      rsaRequestId: this.caseData ? this.caseData.rsaRequestID : null,
      digitalDataType: this.digitalType == "IVR" ? "IVR" : null
    };
    this.dialogRef.close(selectedObj);
  }
}
