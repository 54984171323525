<app-header></app-header>
<div class="maintenance-component">
  <mat-card id="maintenance-template" class="notification">
    <div class="mat-card-container">
      <mat-icon>handyman</mat-icon>
      <div>
        <mat-card-header>
          <mat-card-title>
            <h2>{{ primaryMSG }}</h2>
          </mat-card-title>
        </mat-card-header>
        <hr />
        <mat-card-content>
          <p>{{ secondaryMSG }}</p>
        </mat-card-content>
      </div>
    </div>
    <mat-card-actions style="text-align: right; width: 100%">
      <button
        style="margin-left: 5px"
        color="primary"
        mat-raised-button
        (click)="refresh()"
      >
        <mat-icon
          style="
            color: white;
            font-size: 2em;
            margin: 0;
            width: auto;
            height: auto;
          "
          >autorenew
        </mat-icon>
        Refresh
      </button>
    </mat-card-actions>
  </mat-card>
</div>
