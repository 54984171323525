<div class="body-container page-sign-out">
  <div class="main ">
    <div class="header-logo ">
      <img src="../../../../assets/images/agero-logo.svg " />
    </div>
    <div class="panel ">
      <div class="title-group ">
        <img
          src="../../../../assets/images/icon-representative.svg "
          class="title-icon "
        />
        <h2>Logging out...</h2>
      </div>
      <div class="description ">
        Trouble accessing your account? <br />Please contact your System
        Administrator.
      </div>
    </div>
  </div>
</div>
