<header id="header">
  <div class="header-bar" style="justify-content: space-between">
    <div class="header-logo">
      <img src="../../../assets/images/agero-logo.svg" />
    </div>
    <div class="header-account">
      <div class="logout-link"><a (click)="logOut()">Log Out</a></div>
      <div class="account-link">
        <p class="fullname">{{ userDetails.fullName }}</p>
        <p class="email">{{ userDetails.email }}</p>
      </div>
    </div>
  </div>
</header>
