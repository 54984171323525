<div class="popupOuter title-group">
  <mat-icon class="title-icon">call</mat-icon>
  <h3 mat-dialog-title style="margin: 0px">
    {{ caller }} Call - {{ jobText }}
  </h3>
  <br /><br />
</div>
<!-- <form [formGroup]="ctiScreenPopForm"> -->

<div>
  <ng-container *ngIf="caseData; else newCase">
    <p>
      Phone #: <b>{{ callbackPhone }}</b>
    </p>
    <div class="existing-table">
      <div class="existing-table-wrapper">
        <table id="existing">
          <tr>
            <th>ID</th>
            <th>Client</th>
            <th>Customer Name</th>
            <th>Service</th>
            <th>Status</th>
          </tr>
          <tr>
            <td>{{ caseData.caseId ? caseData.caseId : caseData.rsaRequestID ? caseData.rsaRequestID : null }}</td>
            <td>{{ programName }}</td>
            <td>{{ caseData.firstName }} {{ caseData.lastName }}</td>
            <td>{{ caseData.serviceType ? caseData.serviceType : caseData.ivrServiceType ? caseData.ivrServiceType : null }}</td>
            <td>{{ caseData.status }}</td>
          </tr>
        </table>
      </div>
    </div>
  </ng-container>
  <ng-template #newCase
    ><p>
      Phone #: <b>{{ callbackPhone }}</b>
    </p>
    <!-- <br> -->
    <p>
      Program Name: <b>{{ programName }}</b>
    </p>
  </ng-template>
</div>

<div mat-dialog-actions style="justify-content: flex-end">
  <!-- <button mat-raised-button type="button" (click)="searchForJob()">
        SEARCH FOR JOB
      </button> -->
  <button  *ngIf="isExistingJob"
    mat-raised-button
    color="primary"
    type="button"
    (click)="createNewJob(true)"
  >
  CREATE NEW JOB
  </button>
  <button
    mat-raised-button
    color="primary"
    type="button"
    (click)="createNewJob(false)"
  >
    {{ jobTypeBtnText }}
  </button>
</div>
